<template>
  <div class="row ml-2">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
              <li class="flex-fill p-0">
                <a class="nav-link active" data-toggle="pill" href="#profile">
                  {{cvProfileInfo}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link disabled" data-toggle="pill" href="#about">
                  {{cvAbout}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link disabled" data-toggle="pill" href="#contactsocial">{{cvContact}}</a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link disabled" data-toggle="pill" href="#edit-roles">
                  {{ cvEditRoles }}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link disabled" data-toggle="pill" href="#manage-users">
                  {{ cvManageUsers }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="iq-edit-list-data">
        <div class="tab-content">
          <div class="tab-pane fade active show" id="profile" role="tabpanel">
            <b-card class="mb-4">
              <ValidationObserver ref="orgForm">
                <b-form class="ml-2">
                  <div class="col-md-12 mb-4 pl-0">
                    <h4 class="mb-3">
                      {{ cvOrgTypeLabel }}
                    </h4>
                      <select
                        v-model="vmOrganisationFormData.type"
                        :placeholder="cvSelectLabel + cvSelectOrgTypeLabel"
                        type="text"
                        class="form-control"
                        id="validation_degree">
                      <option v-for="(value, index) of OrgType" :key="index+'OrgType'">{{ index }}</option>
                    </select>
                  </div>
                  <div class="col-md-12 mb-4 pl-0">
                    <h4 class="mb-3">
                      {{cvOrgNameLabel}}
                    </h4>
                    <ValidationProvider
                      name="Organisation Name"
                      rules="required|max:50"
                      v-slot="{ errors }">
                      <input v-model="vmOrganisationFormData.org_name" :placeholder="cvOrgNameLabel" type="text" class="form-control" required  />
                      <span class="text-danger">
                        <span v-if="vmOrganisationFormData.org_name && errors[0]"> Name may not be more than 50 characters. </span>
                        <span v-else> {{ errors[0] }} </span>
                      </span>
                    </ValidationProvider>
                  </div>
                  <div>
                    <h4>
                      Profile Pic
                    </h4>
                    <hr>
                    <div class="form-group row align-items-center">
                      <div class="col-md-12">
                        <div class="profile-img-edit border" style="height: 150px; width: 150px; border-radius: 50%; ">
                          <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden; border-radius: 50%; " />
                          <i v-else class="fas fa-university d-flex justify-content-center align-items-center rounded-circle " style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                          <label class="p-image">
                            <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                            <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Profile Pic')" />
                          </label>
                        </div>
                      </div>
                      <span class="text-danger" ref="proPicError"></span>
                    </div>
                  </div>
                  <div class="mt-4">
                    <h4>
                      Cover Pic
                    </h4>
                    <hr>
                    <div class="form-group row align-items-center">
                      <div class="col-md-12">
                        <div class="profile-img-edit border" style="height: 250px; width: 100%;">
                          <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                          <i v-else class="fas fa-university d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                          <label class="p-image">
                            <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                            <input class="file-upload" type="file" accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                          </label>
                        </div>
                      </div>
                      <span class="text-danger" ref="coverPicError"></span>
                    </div>
                  </div>
                  <div class="col-md-12 mb-4">
                    <label>{{cvOrgAdminEmailLabel}}</label>
                    <ValidationProvider
                      name="Primary Email"
                      rules="required||email"
                      v-slot="{ errors }">
                      <input v-model="vmOrganisationFormData.org_admin_email" :placeholder="cvOrgAdminEmailLabel" type="text" class="form-control"  required />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="mt-4">
                    <b-btn type="button" variant="primary" class="pull-left" @click="organisationAdd()" :disabled="cvLoadingStatus || cvImageLoadingStatus || cvImageError">
                      <b-spinner small class="ml-4 mr-4" v-if="cvLoadingStatus || cvImageLoadingStatus" label="Spinning">
                      </b-spinner>
                      <span v-else>{{cvOrgCreateBtn}}</span>
                    </b-btn>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<style scoped lang="scss">
@media (max-width: 479px) {
  .profile-feed-items li{
    margin: 0px !important;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { socialvue } from "../../../config/pluginInit"
import { Organisations } from "../../../FackApi/api/organisation"
import { Images } from "../../../FackApi/api/image"
import ApiResponse from "../../../Utils/apiResponse"
import { ValidationProvider } from "vee-validate"
import CropImage from "../../../components/cropImage.vue"
import OrgType from "../../../../src/FackApi/json/OrgType.json"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "OrganisationAdd",
  components: {
    ValidationProvider,
    CropImage
  },
  data () {
    return {
      apiName: "orgainsation_add",
      cvSelectLabel: "Select ",
      cvLoadingStatus: false,
      cvImageLoadingStatus: false,
      cvProfileInfo: "Profile",
      cvAbout: "About",
      cvOptionSelectLabel: "Select",
      cvSelectOrgTypeLabel: "Organisation Type",
      cvContact: "Contact",
      cvEditRoles: "Manage Roles",
      cvManageUsers: "Manage Users",
      cvCardTitle: "Edit Organisation",
      cvCardSubHeader: "Edit Organisation ",
      cvSubmitBtn: "Edit",
      cvOrgNameLabel: "Name",
      cvOrgTypeLabel: "Type",
      cvOrgAdminEmailLabel: "Primary Email",
      cvOrgProfilePic: "Profile Pic",
      cvOrgCoverPic: "Cover Pic",
      cvOrgCreateBtn: "Create Organisation",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation",
      cvImageError: false,
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 4 mb.",
      vmOrganisationFormData: Object.assign({}, this.initOrgData()),
      vmOrgProPicImage: Object.assign({}, this.initImageData()),
      vmOrgCoverPicImage: Object.assign({}, this.initImageData()),
      vmProfilePic: "",
      vmCoverPic: "",
      vmOrgProfileImage: Object.assign({}, this.initImageData()),
      cvCountryOptions: [],
      cvCountrySelectValue: {},
      orgObj: {},
      propModuleName: "UNIV",
      propCropImageDialogOpen: false,
      fileSrc: "",
      updateImageType: null,
      propStencilSize: null,
      propCropDialogSize: "md",
      OrgType: OrgType
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * initOrgData
     */
    initOrgData () {
      return {
        org_name: "",
        org_admin_email: ""
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
    },
    /**
     * organisationAdd
     */
    async organisationAdd () {
      this.vmOrganisationFormData.type = this.OrgType[this.vmOrganisationFormData.type]
      if (this.cvImageError) {
        return
      }

      let valid = await this.$refs.orgForm.validate()
      if (!valid) {
        return
      }

      try {
        this.cvLoadingStatus = true
        this.vmOrganisationFormData.user_role = this.userData.user_role

        let organisationAddResp = await Organisations.organisationAdd(this, this.vmOrganisationFormData)
        if (organisationAddResp && !organisationAddResp.resp_status) {
          await ApiResponse.responseMessageDisplay(this, organisationAddResp)
          return false
        }
        if (organisationAddResp.resp_data && organisationAddResp.resp_data.org_id) {
          this.cvLoadingStatus = false
          await this.imageAdd(organisationAddResp.resp_data.org_id)
          await this.$router.push("/organisation_edit/" + organisationAddResp.resp_data.type + "/" + organisationAddResp.resp_data.org_id + "?about=true")
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (orgId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "UNIV",
          "module_id": orgId,
          "image": []
        }

        if (this.vmOrgProPicImage.image) {
          imageObj.image.push(this.vmOrgProPicImage)
        }

        if (this.vmOrgCoverPicImage.image) {
          imageObj.image.push(this.vmOrgCoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await Images.imageProfileAdd(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        this.vmOrgProPicImage = Object.assign({}, this.initImageData())
        this.vmOrgCoverPicImage = Object.assign({}, this.initOrgData())
        imageObj = {}
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmOrgProPicImage = Object.assign({}, this.initImageData())
        this.vmOrgProPicImage.image = {
          "img": img,
          "name": orgImg.name
        }
        this.vmOrgProPicImage.image_type = "profile_pic"
      }
      else {
        this.vmOrgCoverPicImage = Object.assign({}, this.initImageData())
        this.vmOrgCoverPicImage.image = {
          "img": img,
          "name": orgImg.name
        }
        this.vmOrgCoverPicImage.image_type = "cover_pic"
      }
    }
  }
}
</script>
